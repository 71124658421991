import OSS from 'ali-oss';
import api from '@/api/autoVideoLib.js';

export default class Client {
  client = null;

  _tokenExpiredTime = 0;
  _token = null;
  _promise = null;

  get token() {
    // 【解决bug】refreshSTSTokenInterval 不生效
    // 【解决方案】根据请求返回的Expiration时间判断是否过期，没有token的时候调用getAccess方法获取getOssToken
    if (Date.now() >= this._tokenExpiredTime || this._token === null) {
      return null;
    } else {
      return { ...this._token };
    }
  }
  set token(value) {
    // 设置token
    this._token = value;
    // 设置token过期时间
    this._tokenExpiredTime = new Date(value.expiration).getTime();
    //【解决bug】refreshSTSToken 刷新了token，但是下载文件token失效
    //【解决方案】如果已经初始化了，强制修改ossClient中存储的token。
    if (this.client) {
      this.client.options.stsToken = value.security_token;
      this.client.options.accessKeyId = value.access_key_id;
      this.client.options.accessKeySecret = value.access_key_secret;
    }
  }
  /**
   * 请求token
   */
  async requestAccess() {
    // 【解决bug】refreshSTSTokenInterval 不生效
    // 【解决方案】根据请求返回的Expiration时间判断是否过期，过期调用getOssToken 重新获取token，没有过期就使用存储的token
    // 此处代码时配置的oss自动定时刷新不生效，所以在这里自己手动定时刷新
    if (!this.token) {
      const { data } = await api.getOssAuth();
      this.token = data;
    }
    return this.token;
  }
  /**
   * 获取token队列
   */
  getAccess() {
    // 如果已经正在请求token，就返回正在请求的方法
    if (this._promise) {
      return this._promise;
    }
    this._promise = new Promise(async (resolve) => {
      const res = await this.requestAccess();
      this._promise = null;
      resolve(res);
    });
    return this._promise;
  }
  /**
   * oss初始化
   */
  async init() {
    try {
      const access = await this.getAccess();
      this.client = new OSS({
        endpoint: access.endpoint,
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: access.access_key_id,
        accessKeySecret: access.access_key_secret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: access.security_token,
        refreshSTSToken: this.getAccess.bind(this),
        // 刷新临时访问凭证的时间间隔，单位为毫秒。
        // refreshSTSTokenInterval: 15 * 60 * 1000,
        // 填写Bucket名称。
        bucket: access.bucket_name,
      });
    } catch (e) {
      throw new Error('获取oss凭证失败');
    }
  }

  /**
   * 上传
   * @param {string} filename  上传的文件
   * @param {File} file  上传的文件
   * @return { {name: string, url: string} }
   */
  async upload(filename, file) {
    if (!this.client) {
      await this.init();
    }
    // 【解决bug】refreshSTSTokenInterval 不生效
    // 【解决方案】每次上传文件之前读取token
    await this.getAccess();
    const headers = { 'x-oss-forbid-overwrite': 'false' };
    try {
      const res = await this.client.put(filename, file, { headers });
      if (res.message) {
        throw res;
      }
      return { name: res.name, url: res.url };
    } catch (e) {
      throw e;
    }
  }
  /**
   * 下载
   * @param {string} filename  下载的文件名
   * @return { string } 下载地址
   */
  async getResourceUrl(filename) {
    if (!this.client) {
      await this.init();
    }
    // 【解决bug】refreshSTSTokenInterval 不生效
    // 【解决方案】每次下载文件之前读取token
    await this.getAccess();
    const response = {
      'content-disposition': `attachment;`,
    };
    try {
      const url = await this.client.signatureUrl(filename, { response });
      if (typeof url === 'string') {
        return url;
      } else {
        throw url;
      }
    } catch (e) {
      throw e;
    }
  }
}
