<template>
  <div>
    <a-spin :spinning="loading" tip="打包中...">
      <div class="tips">下载时请勿刷新页面，非打包下载请允许浏览器同时下载多个文件</div>
      <a-table :columns="columns" :data-source="downloadList" :pagination="false" :row-key="(r, i) => `${i}`">
        <div slot="status" slot-scope="text, record">
          <a-tag v-if="record.status === '待下载'">{{ record.status }}</a-tag>
          <a-tag color="green" v-else-if="record.status === '下载完成'">{{ record.status }}</a-tag>
          <a-tag color="blue" v-else>{{ record.status }}</a-tag>
        </div>
      </a-table>
    </a-spin>
  </div>
</template>

<script>
import axios from 'axios';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import moment from 'moment';
import OssClient from '@/utils/ossClient';

export default {
  data() {
    return {
      columns: [
        {
          title: '脚本名称',
          dataIndex: 'name',
        },
        {
          title: '状态',
          scopedSlots: { customRender: 'status' },
          width: 200,
        },
      ],
      downloadList: [],
      isZip: false,
      loading: false,
    };
  },
  methods: {
    async download() {
      try {
        const client = new OssClient();
        const zip = new JSZip();
        // 下载文件
        for (const item of this.downloadList) {
          item.status = '下载中...';
          const videoUrl = new URL(item.url);
          const videoFile = videoUrl.pathname.replace('/', '');
          const downloadUrl = await client.getResourceUrl(videoFile);
          const response = await axios.get(downloadUrl, {
            responseType: 'blob',
            onDownloadProgress(ProgressEvent) {
              const progress = (ProgressEvent.loaded / ProgressEvent.total) * 100;
              item.status = `${progress.toFixed(2)}%`;
            },
          });
          if (this.isZip) {
            zip.file(`${item.name}.mp4`, new Blob([response.data]), { binary: true });
            item.status = '待打包';
          } else {
            FileSaver.saveAs(response.data, `${item.name}.mp4`);
            item.status = '下载完成';
          }
        }
        if (this.isZip) {
          this.loading = true;
          zip
            .generateAsync({ type: 'blob' })
            .then((content) => {
              localStorage.downloadList = '[]';
              FileSaver.saveAs(content, `${moment().format('YYYY-MM-DD HH:mm:ss')}.zip`);
              this.downloadList.forEach((item) => {
                if (item.status === '待打包') {
                  item.status = '下载完成';
                }
              });
            })
            .catch(() => {
              this.$message.error('打包失败');
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          localStorage.downloadList = '[]';
        }
      } catch (error) {
        this.$message.error('下载出错');
        console.log('error:', error);
      }
    },
  },
  created() {
    // 获取下载任务
    this.downloadList = JSON.parse(localStorage.downloadList || '[]').map((item) => ({
      ...item,
      status: '待下载',
    }));
    this.isZip = this.$route.query.isZip === 'true';
    if (this.downloadList.length > 0) {
      this.download();
    }
  },
};
</script>

<style scoped lang="scss">
.tips {
  margin-bottom: 10px;
}
</style>
